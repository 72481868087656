export const BROWSER = 'browser';
export const NATIVE = 'native';
export const KITE = 'KITE';
export const ANDROID = 'android';
export const IOS = 'ios';

export enum BuildAppNames {
  XBEES = 'xbees',
  XHOPPERS = 'xhoppers',
  COLLABORATION = 'collaboration',
}

export function getAppLangConstant(appName: BuildAppNames) {
  switch (appName) {
    case BuildAppNames.XBEES:
      return LANG_CONSTANTS.XBEES;
    case BuildAppNames.XHOPPERS:
      return LANG_CONSTANTS.XHOPPERS;
    default:
      return LANG_CONSTANTS.XBEES;
  }
}

export const LANG_CONSTANTS = Object.freeze({
  XHOPPERS: 'x-hoppers',
  XBEES: 'x-bees',
  APPLE_STORE: 'App Store',
  PLAY_STORE: 'Play Store',
  XBEES_CONFERENCE: 'x-bees conference',
});

// Channel used to bound to root channel for event listeners callbacks.
export const APP_CHANNEL = {effects: [], notify: null};
export const APP_EMIT = (effect) => {
  APP_CHANNEL.effects.push(effect);
  if (APP_CHANNEL.notify) {
    APP_CHANNEL.notify();
  }
};

export const APP_STATE = Object.freeze({
  ACTIVE: 'active',
  BACKGROUND: 'background',
});

export const APP_EVENTS = Object.freeze({
  START: 'app/START',
  FOCUS: 'app/FOCUS',
  BLUR: 'app/BLUR',
  NETWORK_CHANGED: 'app/NETWORK_CHANGED',
  THEME_CHANGED: 'app/THEME_CHANGED',
  TIME_FORMAT_CHANGED: 'app/TIME_FORMAT_CHANGED',
  ERROR: 'app/ERROR',
});

export const PUSH_NOTIFICATION_CATEGORIES = Object.freeze({
  MESSAGE_NEW: 'MESSAGE_NEW',
  MESSAGE_UPDATED: 'MESSAGE_UPDATED',
  MESSAGE_DELETED: 'MESSAGE_DELETED',
  CHANNEL_DELETE: 'CHANNEL_DELETE',
  CHANNEL_READ: 'CHANNEL_READ',
  UNREAD_MESSAGES: 'UNREAD_MESSAGES',
  CONFERENCE_STARTED: 'CONFERENCE_STARTED',
  CONFERENCE_ENDED: 'CONFERENCE_ENDED',
  CALL: 'call',
  PICKUP: 'pickup',
  MISSED_CALL: 'missed-call',
  CANCELLED: 'cancelled',
  UNKNOWN: 'unknown',
});

export const PUSH_NOTIFICATION_ACTIONS = Object.freeze({
  OPEN: 'push.notification/OPEN',
  OPEN_CONVERSATION: 'push.notification/OPEN_CONVERSATION',
  OPEN_MISSED_CALLS: 'push.notification/OPEN_MISSED_CALLS',
  OPEN_CALL: 'push.notification/OPEN_CALL',
});

export const LEARN_MORE_XBEES_LINK = 'https://www.x-bees.com/';

export const DEVICE_TOKEN_EVENTS = Object.freeze({
  VOIP_REGISTERED: 'device/VOIP_REGISTERED',
  VOIP_RESTORED: 'device/VOIP_RESTORED',
  PTT_REGISTERED: 'device/PTT_REGISTERED',
  PTT_RESTORED: 'device/PTT_RESTORED',
  REMOTE_REGISTERED: 'device/REMOTE_REGISTERED',
  REMOTE_RESTORED: 'device/REMOTE_RESTORED',
  FIREBASE_REGISTERED: 'device/FIREBASE_REGISTERED',
  FIREBASE_RESTORED: 'device/FIREBASE_RESTORED',
});

export const GETSTREAM_DOMAIN = 'chat.wildix-chat.com';

export const APP_FONT_FAMILY =
  'Mulish, "apple color emoji", "Android Emoji", TwemojiMozilla, Helvetica, "Segoe UI", Arial, sans-serif';

export enum ThemeSettingMode {
  DARK = 'dark',
  LIGHT = 'light',
  AUTO = 'auto',
}

export type ThemeMode = ThemeSettingMode.LIGHT | ThemeSettingMode.DARK;
