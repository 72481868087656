import {BuildAppNames} from './constants';

import {APP_BUILD_NAME} from './appName';

const oldColors = {
  //Main colors

  // whiteLight: '#FFFFFF',
  // whiteDark: '#FFFFFF',

  transparent: 'rgba(0, 0, 0, 0)',

  // white005Light: '#FFFFFF0C',
  white005Dark: '#FFFFFF0C',

  white02Light: '#FFFFFF33',
  white02Dark: '#FFFFFF33',

  // white012: 'rgba(255, 255, 255, 0.12)',

  // black: '#000000',
  blackLight: '#292C31',
  // black07Light: '#090F16B2',
  // blackDark: '#292C31',
  // blackA008: 'rgba(0, 0, 0, 0.08)',
  blackA004: 'rgba(0, 0, 0, 0.04)',
  // blackA05: 'rgba(0, 0, 0, 0.5)',
  // black22Light: 'rgba(9, 15, 22, 0.12)',
  // black34Dark: '#141B22',
  black71: '#2A3847',

  // backgroundLight: '#FFFFFF',
  backgroundDark: '#090F16',

  // white03Light: '#FFFFFF',
  white03Dark: '#1D2229',

  // androidSwitchThumbDark: '#F7F7F7', //android specific, dark theme

  // background06Light: '#FFFFFF99',
  // background06Dark: 'rgba(9, 15, 22, 0.6)',
  // background07Dark: 'rgba(9, 15, 22, 0.7)',
  background08Dark: '#090F16CC',

  // mainTextLight: '#292C31',
  // mainTextDark: '#FFFFFF',

  //Secondary colors
  mainBlueLight: '#167BC8',
  mainBlueDark: '#0680DE',
  // mainBlueAccent: '#1367A8',
  // mainBlueAccentDark: '#17AEFF',
  // mainBlue182: '#1367A8',
  mainBlueLightA001: 'rgba(22, 123, 200, 0.1)',
  // mainBlueLightA008: 'rgba(22, 123, 200, 0.08)',
  // mainBlueLight204: '#0576CC',
  // mainBlueLight250: '#35A4FA',

  mainBlue02Light: '#167BC833',
  mainBlue02Dark: '#0680DE33',

  mainBlue03Light: '#167BC84D',
  // mainBlue03Dark: '#0680DE4D',

  // mainBlue05Light: '#167BC880',
  mainBlue05Dark: '#0680DE80',

  mainBlue08Light: '#167BC8CC',

  gray01Light: '#83898F',
  gray01Dark: '#868B90',

  gray01008Light: '#83898F14',
  // gray01008Dark: '#83898F14',

  gray0101Light: '#83898F19',
  gray0121Light: '#98A5AE1E',
  gray0101Dark: '#868B9119',

  gray0106Light: '#83898F99',
  gray0106Dark: '#868B9199',

  gray111: '#666A6F',

  gray02Light: '#CCCFD1',
  gray02Dark: '#CCCFD1',

  gray02015Light: '#CCCFD126',
  gray02015Dark: '#CCCFD126',

  gray03Light: '#868B91',
  // gray03Dark: '#FFFFFF',

  gray04Light: '#E2E2E2',
  gray04Dark: '#4F4F4F',

  gray05Light: '#E4E4E4',
  gray05Dark: '#242b34',

  // gray0504Light: '#E4E4E466',
  // gray0504Dark: '#242B3466',
  // gray0506Dark: 'rgba(36,43,52,0.6)',
  // gray0508Dark: 'rgba(36,43,52,0.8)',
  gray162Dark: '#9D9FA2',
  gray162A05Dark: 'rgba(157, 159, 162, 0.5)',

  gray06Light: '#E4E4E4',
  gray06Dark: '#575B5E',

  // gray07Light: '#ECF1F4',
  // gray07Dark: '#ECF1F4',

  gray08Light: '#F7F7F7',
  gray08Disabled: '#eaeaea',

  gray131Light: '#7F8083',
  gray131A05Light: 'rgba(127, 128, 131, 0.5)',

  gray225Dark: '#DFE0E1',

  // gray59Dark: '#2B333B',

  gray: '#C2C2C2',
  gray04: '#C2C2C266',

  //Accent colors
  green01Light: '#32D74B',
  green01Dark: '#32D74B',
  greenHover: '#4DAB5C',
  green194A01: 'rgba(36, 194, 60, 0.1)',
  green194A02: 'rgba(36, 194, 60, 0.2)',

  red015Light: '#F0D2D2',
  red238A01: 'rgba(238, 65, 65, 0.1)',
  red238A02: 'rgba(238, 65, 65, 0.2)',
  // red238A008: 'rgba(238, 65, 65, 0.08)',
  dangerousBright: '#FF2636',
  // dangerousLight: '#EE4141',
  dangerousDark: '#FF453A',
  dangerousAccent: '#F75A5B',

  // yellow01Light: '#FACC2A',
  // yellow01Dark: '#FACC2A',

  // yellow252Dark: '#FCD751',

  // postItYellowLight: '#FFE071',
  // postItYellowDark: '#FFD749',

  // postItRedLight: '#FF5B5B',
  // postItRedDark: '#FE5757',

  //Addition colors
  // tabIconUsualLight: '#667278',
  tabIconUsualDark: '#868B91',

  // tabIconActiveLight: '#167BC8',
  tabIconActiveDark: '#0680DE',

  // chatInput4Light: '#164C740A',
  chatInput6Light: '#164C740F',

  chatInput40Light: '#164C7466',
  chatInput40Dark: '#164C7466',

  // chatInput8Light: '#164C7414',
  chatInput8Dark: '#FFFFFF14',

  chatInput15Light: '#164C7426',
  chatInput15Dark: '#FFFFFF26',

  // plusButtonLight: '#167BC8',
  // plusButtonDark: '#ADADAD',

  // deepBlueLight: '#164C74',
  // deepBlueDark: '#164C74',

  // alertBackgroundLight: '#FFFFFF',
  alertBackgroundDark: '#21272D',

  backgroundCallFlow55Light: '#1C20248C',
  backgroundCallFlow55Dark: '#1C20248C',

  greenCallsLight: '#11C23E',
  // greenCallsDark: '#11C23E',

  blueCallsLight: '#167BC8CC',
  blueCallsDark: '#0680DECC',

  screenFade04Light: '#1C202466',
  screenFade07Dark: '#090F16B3',

  // actionsLight: '#E2E2E2',
  // actionsDark: '#1F2122',

  // cancelLight: '#FFFFFF',
  cancelDark: '#2C2C2E',

  spinnerLight: '#4F4F4F',
  spinnerDark: '#4F4F4F',

  dialpadUnderlayLight: '#00000026',
  dialpadUnderlayDark: '#FFFFFF26',

  menuItemHoveredLight: '#e3eaed',
  // menuItemHoveredDark: '#e3eaed', // TODO
  lightBlue238: '#D2E1EE',

  webCallBackgroundColor: '#DDE7F0',
  // webCallHoldBackgroundColor: '#ECF1F4',

  deepBlue08: '#164C7414',

  menuDivider: '#F7F7F7',
  menuHover: '#808080',

  dropHighlight: '#32D74B19',
  // composeBackroundColor: '#ecf1f4',

  dialogButtonLight: '#F7F7F7',
  dialogButtonDark: '#F7F7F71A',

  messageMenuItemHoveredLight: '#F7F7F7',
  // messageMenuItemHoveredDark: '#F7F7F7',

  black12: '#0c0d0f',
  activeCallBackground: '#1C2024',
  activeCallItemUnHoldIconBackgroundColor: '#2F3133',
  // activeCallItemAnswerIconBackgroundColor: '#24C23C',
  activeCallItemGray01BackgroundColor: 'rgba(131,137,143,0.1)',
  activeCallItemGray04BackgroundColor: 'rgba(28,32,36,0.4)',
  activeCallItemGray08BackgroundColor: 'rgba(28,32,36,0.8)',

  // clearColor: 'rgba(0,0,0,0)',

  tagBackgroundLight: '#AEB0B1',
  tagBackgroundDark: '#575B5E',

  // overlayBackgroundLight: '#FFFFFF',
  overlayBackgroundDark: '#32353A',

  // inputBackgroundLight: '#f6f6f6',
  // inputBackgroundDark: '#373A3D',

  closeButtonBackgroundLight: '#E6E9EB',
  // closeButtonBackgroundDark: '#E6E9EB',

  // editMessageLabelLight: '#24C23C',
  // editMessageLabelDark: '#27D341',

  incomingMessageQuoteBackgroundLight: '#DCE4EC',
  // incomingMessageQuoteBackgroundDark: '#DCE4EC',

  incomingMessageForwardBackgroundLight: '#DCE4EC',
  // incomingMessageForwardBackgroundDark: '#DCE4EC',

  editedStateInputBorderLight: '#8CDC9C',
  // editedStateInputBorderDark: '#8CDC9C',

  avatarBackgroundLight: '#AEB0B1',
  avatarBackgroundDark: '#5E6062',

  // avatarGroupBackgroundLight: '#164C74',
  // avatarGroupBackgroundDark: '#164C74',

  // borderSeparatorLight: '#F3F3F3',
  // borderSeparatorDark: '#F3F3F3',

  // viewConversationSubstrateLight: '#F7F7F7',
  // viewConversationSubstrateDark: '#2F3133',

  // groupIconShadowLight: '#b2d1e9',
  // groupIconShadowDark: '#122940',

  editConversationDelimiterLight: '#C9C9C9',
  editConversationDelimiterDark: '#5E6062',

  // scrollToBottomButtonBackgroundLight: '#FFFFFF',
  scrollToBottomButtonBackgroundDark: '#ECF4FB',

  gray35: '#1A1F23',
  blue53: '#282F35',
  blue194: '#3e7ac2',
  blue212: '#1d74d4',
  blue164: '#146CA4',
  // orange: '#FF9015',

  paleBlue: '#F1F4F7',

  // red01015Light: 'rgba(238, 65, 65, 0.15)',
  red01015Dark: 'rgba(255, 69, 58, 0.15)',

  // red01024Light: 'rgba(238, 65, 65, 0.24)',
  red01024Dark: 'rgba(255, 69, 58, 0.24)',

  red01025Light: 'rgba(238, 65, 65, 0.25)',
  red01025Dark: 'rgba(255, 69, 58, 0.25)',

  red0102Light: 'rgba(238, 65, 65, 0.2)',
  redA400: 'rgba(255, 23, 68, 1)', //red.A400 of @mui/material/colors

  // greenLight: '#24C23C',
  // greenLightA05: '#24C23C50',
  greenDark: '#27D341',
  greenDarkA05: '#27D34150',

  greenTrack199: '#34C759',

  blackAlpha03: 'rgba(0, 0, 0, 0.3)',
  blackAlpha015: 'rgba(0, 0, 0, 0.15)',

  secondaryBlue236: '#5286EC',
  secondaryBlue234: '#3E78EA',

  secondaryRed228: '#E43F01',
  secondaryRed208: '#D03A01',

  // deepBlue116A08: 'rgba(22, 76, 116, 0.04)',

  // grayB1Light: '#AEAFB1',
  // gray6FDark: '#666A6F',

  // gray24: '#242A2E',

  // blueC8alpha14: '#167BC814',

  // lightBlue244Light: '#ECF1F4',
  // gray177Light: '#AEAFB1',
  // black22A15: '#090F161F',
  // blue200Light: '#167BC8',
  // gray174A06: '#98A5AE0F',
  // gray174A12: '#98A5AE1F',
};

const xbeesThemeColors = {
  primary_main_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  primary_main_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  primary_main_light_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  primary_main_light_DM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM,
  primary_main_dark_LM: 'rgba(19, 103, 168, 1)', // #1367A8ff blueAccent168__LM
  primary_main_dark_DM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM,
  primary_main_dark_disabled_DM: 'rgba(23, 174, 255, 0.3)', // #17AEFF4D blueAccent255_30__DM
  primary_disabled_LM: 'rgba(5, 118, 204, 0.18)', // #0576CC2E blue204_18__DM
  primary_disabled_DM: 'rgba(130, 186, 229, 0.18)', //#82BAE52E lightBlue130_18__DM
  primary_button_disabled_LM: 'rgba(177, 207, 232, 1)', // #B1CFE8FF primaryDisabled_LM
  primary_button_disabled_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2E blue204_18__DM
  primary_button_disabled_text_LM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  primary_button_disabled_text_DM: 'rgba(102, 106, 111, 1)', // #666A6Fff  gray111__DM
  secondary_hover_LM: 'rgba(22, 123, 200, 0.08)', // #167BC814 blue200_08__LM
  secondary_hover_DM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM
  hover_state_LM: 'rgba(22, 76, 116, 0.04)', // #164C74a deepBlue116_04
  hover_state_DM: 'rgba(127, 161, 186, 0.04)', // #7FA1BA0A lightBlue186_04
  text_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM,
  text_DM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM
  text_highlight_LM: 'rgba(6, 128, 222, 1)', // #167BC8 mainBlueLight
  text_highlight_search_LM: 'rgba(6, 128, 222, 1)', // #167BC8 mainBlueLight
  text_highlight_search_DM: 'rgba(6, 128, 222, 1)', // #167BC8 mainBlueLight,
  button_selected_LM: 'rgba(22, 76, 116, 0.08)', // #164C7414 deepBlue116_08
  button_selected_DM: 'rgba(127, 161, 186, 0.14)', // #7FA1BA23 lightBlue186_14
  button_active_state_LM: 'rgba(22, 123, 200, 0.08)', // #167BC814 blue200_08__LM
  button_active_state_DM_08: 'rgba(5, 118, 204, 0.08)', // #0576CC14 blue204_08__DM
  button_active_state_DM_14: 'rgba(5, 118, 204, 0.14)', // blue204_14__DM
  button_active_state_DM_18: 'rgba(5, 118, 204, 0.18)', // #0576CC2e blue204_18__DM
  button_active_state_DM_50: 'rgba(5, 118, 204, 0.5)', // #0576CC80 blue204_50__DM
  button_hover_active_state_LM: 'rgba(210, 225, 238, 1)', // #D2E1EEff lightBlue238
  button_hover_active_state_DM: 'rgba(127, 161, 186, 0.14)', // #7FA1BA23 lightBlue186_14
  button_icon_LM: 'rgba(117, 118, 121, 1)', // #757679ff gray117__LM
  button_active_icon_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  button_active_icon_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  button_send_hover_LM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM
  button_send_hover_DM: 'rgba(19, 103, 168, 1)', // #1367A8ff blueAccent168__LM
  button_outlined_text_LM: 'rgba(19, 103, 168, 1)', // #1367A8ff blueAccent168__LM
  button_outlined_text_204_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  button_outlined_text_250_DM: 'rgba(53, 164, 250, 1)', // #35A4FAff blue250
  button_outlined_text_255_DM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM
  primary_button_hover_LM: 'rgba(19, 103, 168, 1)', // #1367A8ff blueAccent168__LM
  primary_button_hover_DM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM
  button_outlined_disabled_text_LM: 'rgba(22, 123, 200, 0.5)', // #167BC880 blue200_50__LM
  button_outlined_disabled_text_DM: 'rgba(5, 118, 204, 0.5)', // #0576CC80 blue204_50__DM
  message_outgoing_background_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM,
  message_outgoing_background_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  message_outgoing_quote_background_LM: 'rgba(19, 103, 168, 1)', // #1367A8ff blueAccent168__LM
  message_outgoing_quote_background_DM: 'rgba(53, 164, 250, 1)', // #35A4FAff blue250
  message_incoming_background_LM: 'rgba(236, 241, 244, 1)', // #ECF1F4ff lightBlue244__LM
  message_incoming_background_DM: 'rgba(20, 27, 34, 1)', // #141B22ff black34__DM
  message_incoming_quote_background_LM: 'rgba(236, 241, 244, 1)', // #ECF1F4ff lightBlue244__LM
  message_incoming_quote_background_DM: 'rgba(43, 51, 59, 1)', // #2B333Bff gray59__DM
  message_text_LM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  message_text_DM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  message_link_LM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  message_link_DM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  container_background_LM: 'rgba(236, 241, 244, 1)', // #ECF1F4ff lightBlue244__LM
  container_background_DM: 'rgba(36, 42, 46, 1)', // #242A2Eff gray46__DM
  input_focused_border_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  input_focused_border_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  chip_background_LM: 'rgba(22, 123, 200, 0.18)', // #167BC82e blue200_18__LM
  chip_background_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2e blue204_18__DM
  call_dialog_background_LM: 'rgba(210, 225, 238, 1)', // #D2E1EEff lightBlue238
  call_dialog_background_DM: 'rgba(18, 28, 37, 1)', // #121C25 black37_18_DM
  call_popup_item_background_DM: 'rgba(127, 161, 186, 0.14)', // #7FA1BA23 lightBlue186_14
  call_history_media_button_background_DM: 'rgba(5, 118, 204, 0.08)', // #0576CC14
  button_on_gray_hover_LM: 'rgba(22, 123, 200, 0.08)', // #167BC814 blue200_08__LM
  button_on_gray_hover_08_DM: 'rgba(5, 118, 204, 0.08)', // #0576CC14 blue204_08__DM
  button_on_gray_hover_18_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2e blue204_18__DM
  button_on_gray_active_LM: 'rgba(210, 225, 238, 1)', // #D2E1EEff lightBlue238
  button_on_gray_active_hover_LM: 'rgba(22, 123, 200, 0.18)', // #167BC82e blue200_18__LM
  button_on_gray_active_08_DM: 'rgba(5, 118, 204, 0.08)', // #0576CC14 blue204_08__DM
  button_on_gray_active_18_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2e blue204_18__DM
  secondary_button_hover_LM: 'rgba(236, 244, 251, 1)', // #ECF4FBFF secondaryHover__LM
  secondary_button_hover_DM: 'rgba(22, 76, 116, 1)', // #164C74ff deepBlue116
  missed_unread_background_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  missed_unread_background_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  missed_unread_mark_background_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  missed_unread_mark_background_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  close_icon_default_LM: 'rgba(0, 0, 0, 0.54)',
  button_background_DM: '#0680DE', // mainBlueDark
  integration_button_background_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2e blue204_18__DM
  audio_attachment_LM: 'rgba(255, 255, 255, 0.16)', // #FFFFFF29 white00_16
  file_size_outgoing_LM: '#E2E2E2', // gray04Light
  file_size_outgoing_DM: '#E2E2E2', // gray04Light
  inbox_controls_button_hover_LM: 'rgba(22, 123, 200, 0.18)', // #167BC82e blue200_18__LM
  inbox_controls_button_hover_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2e blue204_18__DM
  info_frame_accordion_DM: 'rgba(152, 165, 174, 0.12)', // #98A5AE1f gray174_12
  info_frame_accordion_expanded_DM: 'rgba(32, 39, 46, 1)', // #20272Eff black46__DM
  info_frame_accordion_hover_LM: 'rgba(22, 123, 200, 0.08)', // #167BC814 blue200_08__LM
  info_frame_accordion_hover_DM: 'rgba(127, 161, 186, 0.12)', // #7FA1BA1F lightBlue186_12
  members_add_button_hover_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  incoming_message_animation_LM: 'rgba(199, 219, 237, 1)', // #C7DBEDff lightBlue237
  incoming_message_animation_DM: 'rgba(22, 76, 116, 1)', // #164C74ff deepBlue116
  outgoing_message_animation_LM: 'rgba(53, 164, 250, 1)', // #35A4FAff blue250
  outgoing_message_animation_DM: 'rgba(22, 76, 116, 1)', // #164C74ff deepBlue116
  main_gradient_start_LM: '',
  main_gradient_start_DM: '',
  main_gradient_finish_LM: '',
  main_gradient_finish_DM: '',
  outgoing_reference_link_background_LM: '#FFFFFF33', // white02Light
  transfer_button_LM: 'rgb(177, 207, 232)', // #B1CFE8 blue232_LM
  transfer_button_DM: 'rgb(177, 207, 232)', // #B1CFE8 blue232_LM
  disabled_transfer_button_text_DM: 'rgba(42, 56, 71, 1)', // #2A3847ff black71__DM
  transfer_item_hover_DM: 'rgba(127, 161, 186, 0.04)', // #7FA1BA0A lightBlue186_04
  brand_LM: 'rgba(232, 125, 7, 1)', // #E87D07FF orange232__LM
  brand_DM: 'rgba(255, 181, 0, 1)', // #FFB500ff orange255
  feedback_button_hover_LM: 'rgba(232, 125, 7, 0.1)', //#E87D0719 orange232_10__LM
  feedback_button_hover_DM: 'rgba(60, 54, 47, 1)', // #3C362FFF gray47
  empty_results_rect_LM: '',
  empty_results_rect_DM: '',
  checkbox_checked_LM: 'rgb(177, 207, 232)', // #B1CFE8 blue232_LM
  checkbox_checked_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2E blue204_18__DM
  insights_playback_controls_background_LM: 'rgba(22, 123, 200, 0.08)', // #167BC814 blue200_08__LM
  insights_playback_controls_background_DM: 'rgba(30, 37, 45, 0.8)', // #1E252Dcc black45_80__DM
  insights_playback_controls_button_active_LM: 'transparent',
  insights_playback_controls_button_active_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2E blue204_18__DM
  insights_playback_controls_button_hover_LM: 'rgba(22, 76, 116, 0.04)', // #164C74a deepBlue116_04
  insights_playback_controls_button_hover_DM: 'rgba(5, 118, 204, 0.08)', // #0576CC14 blue204_08__DM
  insights_playback_controls_border_LM: 'rgba(210,230,246, 1)', // #D2E6F6 lightblue_246__LM
  insights_playback_controls_border_DM: 'rgba(152, 165, 174, 0.12)', // #98A5AE1f gray174_12
  insights_playback_chunk: 'rgba(53, 164, 250, 1)', // #35A4FAff blue250
  insights_time_code_chunk_LM: 'rgba(19, 103, 168, 0.2)', // #1367A8 blueAccent168_20__LM
  insights_time_code_chunk_DM: 'rgba(19, 103, 168, 0.2)', // #1367A8 blueAccent168_20__LM
  insights_slider_seek_hover_LM: 'rgba(133, 183, 222, 1)', // #85B7DE blue222__LM
  insights_slider_seek_hover_DM: 'rgba(19, 60, 92, 1)', // #133C5C blue_dark_92__DM
  insights_comments_card_background_LM: 'rgb(244, 250, 255)', // #F4FAFF blue_light_255_LM
  insights_comments_card_background_DM: 'rgb(18, 36, 50)', // #122432 blue_light_50_DM
  insights_comments_card_hex_background_LM: 'rgba(139, 143, 156, 1)', // #8B8F9Cff grayBlue156__LM
  insights_comments_card_hex_background_DM: 'rgba(42, 56, 71, 1)', // #2A3847ff black71__DM
  insights_comments_button_hover_background_DM: 'rgba(5, 118, 204, 0.14)', // blue204_14__DM
  insights_translate_button_hover_DM: 'rgba(127, 161, 186, 0.08)', // #7FA1BA14 lightBlue186_08
  insights_transcription_highlight_LM: 'rgba(22, 123, 200, 0.18)', // #167BC82e blue200_18__LM
  insights_transcription_highlight_DM: 'rgba(23, 174, 255, 0.3)', // #17AEFF4D blueAccent255_30__DM
  pinned_indicator_active_regular_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  pinned_indicator_active_regular_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  pinned_indicator_active_dynamic_LM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  pinned_indicator_active_dynamic_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  pinned_indicator_LM: 'rgba(177, 207, 232, 1)', // #B1CFE8FF primaryDisabled_LM
  pinned_indicator_DM: 'rgba(5, 118, 204, 0.5)', // #0576CC80 blue204_50__DM
  pinned_indicator_corner_LM: 'rgba(5, 118, 204, 0.18)', // #0576CC2E blue204_18__DM
  pinned_indicator_corner_DM: 'rgba(130, 186, 229, 0.18)', //#82BAE52E lightBlue130_18__DM
  secondary_button_background__LM: 'rgba(152, 165, 174, 0.12)', // #98A5AE1f gray174_12
  info_frame_accordion_title_active_hover__LM: 'rgba(22, 123, 200, 0.08)', // #167BC814 blue200_08__LM
  info_frame_accordion_title_active_hover__DM: 'rgba(127, 161, 186, 0.12)', // #7FA1BA1F lightBlue186_12
  predefined_reply_button_hover_LM: 'rgba(22, 123, 200, 0.12)', // #167BC81E blue200_12__LM
  predefined_reply_button_hover_DM: 'rgba(127, 161, 186, 0.12)', // #7FA1BA1F lightBlue186_12

  integrations_icon_action_regular_LM: 'rgba(139, 143, 156, 1)', // #8B8F9Cff grayBlue156__LM
  integrations_icon_action_regular_DM: 'rgba(117, 118, 121, 1)', // #757679ff gray117__LM
  integrations_icon_action_field_LM: 'rgba(139, 143, 156, 1)', // #8B8F9Cff grayBlue156__LM
  integrations_icon_action_field_DM: 'rgba(157, 159, 162, 1)', // #9D9FA2ff gray162__LM
  integrations_icon_action_regular_hover_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  integrations_icon_action_regular_hover_DM: 'rgb(23,174,255)', // #35A4FAff blue250
  integrations_icon_action_regular_hover_mui_DM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM
  integrations_icon_color_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  integrations_icon_color_DM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM
  integrations_action_background_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  integrations_action_background_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  integrations_alert_background_LM: 'rgba(22, 123, 200, 0.08)', // #167BC814 blue200_08__LM
  integrations_alert_background_DM: 'rgba(5, 118, 204, 0.14)', // blue204_14__DM
  integrations_button_disabled_background_LM: 'rgba(152, 165, 174, 0.06)', // #98A5AE0F gray174_06
  integrations_button_disabled_background_DM: 'rgba(5, 118, 204, 0.08)', // #0576CC14 blue204_08__DM
  integrations_accordion_badge_LM: 'rgba(199, 219, 237, 1)', // #C7DBEDff lightBlue237
  integrations_accordion_badge_DM: 'rgba(5, 118, 204, 0.5)', // #0576CC80 blue204_50__DM
  integrations_icon_next_step_LM: 'rgb(177, 207, 232)', // #B1CFE8 blue232_LM
  integrations_icon_next_step_DM: 'rgba(5, 118, 204, 0.5)', // #0576CC80 blue204_50__DM
  integrations_sf_badge_LM: 'rgba(210, 225, 238, 1)', // #D2E1EEff lightBlue238__LM
  integrations_sf_badge_DM: 'rgba(5, 118, 204, 0.08)', // #0576CC14 blue204_08__DM
  integrations_icon_text_editor_LM: 'rgb(23,174,255)', // #35A4FAff blue250
  integrations_icon_text_editor_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2E blue204_18__DM
  integrations_tooltip_background_LM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  integrations_tooltip_background_DM: 'rgba(43, 51, 59, 1)', // #2B333Bff gray59__DM
  integrations_tooltip_text_LM: 'rgba(41, 44, 49, 1)', // #292C31ff black49__LM
  integrations_tooltip_text_DM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  integrations_steps_unfinished_background: 'rgba(156, 156, 156, 0.18)', // #9C9C9C2E
};

const xhoppersColorStyles = {
  primary_main_LM: 'rgba(63, 134, 66, 1)', // #3F8642ff
  primary_main_DM: 'rgba(63, 134, 66, 1)', // #3F8642ff
  primary_main_dark_LM: 'rgba(32, 84, 34, 1)', // #205422ff
  primary_main_dark_DM: 'rgba(105, 205, 80, 1)', // #69CD50ff
  primary_main_light_LM: 'rgba(85, 186, 55, 1)', // #55BA37ff
  primary_main_light_DM: 'rgba(52, 114, 54, 1)', // #347236
  primary_disabled_LM: 'rgba(212, 233, 212, 1)', // #D4E9D4ff
  primary_disabled_DM: 'rgba(22, 200, 58, 0.12)', // #16C83A2E
  primary_disabled_text_LM: 'rgba(192, 222, 192, 1)', // #C0DEC0
  primary_disabled_text_DM: 'rgba(5, 118, 204, 0.5)', // #0576CC80
  primary_disabled_hover_LM: 'rgba(63, 134, 66, 0.28)', // #3F864247
  secondary_hover_state_LM: 'rgba(85, 186, 55, 0.12)', // #55BA371F
  secondary_hover_state_DM: 'rgba(22, 200, 58, 0.12)', // #16C83A1F
  secondary_hover_on_white_state_LM: 'rgba(85, 186, 55, 0.12)', // #55BA371F
  states_active_LM: 'rgba(85, 186, 55, 0.12)', // #55BA371F
  states_active_DM: 'rgba(119, 203, 136, 0.12)', // #77CB881F
  states_hover_LM: 'rgba(0, 0, 0, 0.04)', // #0000000A
  states_hover_DM: 'rgba(185, 200, 193, 0.08)', // #B9C8C114
  secondary_background_message_incoming_LM: 'rgba(243, 243, 243, 1)', // #F3F3F3
  secondary_background_message_incoming_DM: 'rgba(27, 32, 37, 1)', // #1B2025
  secondary_background_message_outgoing_LM: 'rgba(228, 244, 223, 1)', // #E4F4DFFF
  secondary_background_message_outgoing_DM: 'rgba(85, 186, 55, 0.16)', // ##55BA3729
  secondary_background_message_action_outgoing_LM: 'rgba(212, 233, 212, 1)', // #D4E9D4
  secondary_background_message_action_outgoing_DM: 'rgba(199, 219, 237, 1)', // #C7DBED
  secondary_background_active_state_LM: 'rgba(244, 244, 244, 1)', // #F4F4F4
  secondary_background_active_state_DM: 'rgba(18, 28, 37, 1)', // #121C25
};

const xhoppersThemeColors = {
  primary_main_LM: xhoppersColorStyles.primary_main_LM,
  primary_main_DM: xhoppersColorStyles.primary_main_DM,
  primary_main_light_LM: xhoppersColorStyles.primary_main_light_LM,
  primary_main_light_DM: xhoppersColorStyles.primary_main_light_DM,
  primary_main_dark_LM: xhoppersColorStyles.primary_main_dark_LM,
  primary_main_dark_DM: xhoppersColorStyles.primary_main_dark_DM,
  primary_main_dark_disabled_DM: xhoppersColorStyles.primary_disabled_DM,
  primary_disabled_LM: xhoppersColorStyles.primary_disabled_LM,
  primary_disabled_DM: xhoppersColorStyles.primary_disabled_DM,
  primary_button_disabled_LM: xhoppersColorStyles.primary_disabled_hover_LM,
  primary_button_disabled_DM: xhoppersColorStyles.primary_disabled_DM,
  secondary_hover_LM: xhoppersColorStyles.secondary_hover_state_LM,
  secondary_hover_DM: xhoppersColorStyles.states_active_DM,
  hover_state_LM: xhoppersColorStyles.states_hover_LM,
  hover_state_DM: xhoppersColorStyles.states_hover_DM,
  text_LM: xhoppersColorStyles.primary_main_dark_LM,
  text_DM: xhoppersColorStyles.primary_main_dark_DM,
  text_highlight_LM: xhoppersColorStyles.primary_main_LM,
  text_highlight_search_LM: 'rgba(6, 151, 12, 1)', // #06970Cff
  text_highlight_search_DM: xhoppersColorStyles.primary_main_dark_DM,
  button_selected_LM: xhoppersColorStyles.states_active_LM,
  button_selected_DM: xhoppersColorStyles.states_active_DM,
  button_active_state_LM: 'rgba(235, 247, 231, 1)', // #EBF7E7
  button_active_state_DM_08: xhoppersColorStyles.primary_disabled_DM,
  button_active_state_DM_14: xhoppersColorStyles.primary_disabled_DM,
  button_active_state_DM_18: xhoppersColorStyles.primary_disabled_DM,
  button_active_state_DM_50: xhoppersColorStyles.primary_disabled_DM,
  button_hover_active_state_LM: xhoppersColorStyles.primary_disabled_LM,
  button_hover_active_state_DM: 'rgba(22, 200, 58, 0.28)', //#16C83A47
  button_icon_LM: 'rgba(147, 147, 147, 1)', // #939393
  button_active_icon_LM: xhoppersColorStyles.primary_main_LM,
  button_active_icon_DM: xhoppersColorStyles.primary_main_dark_DM,
  button_outlined_text_LM: xhoppersColorStyles.primary_main_LM,
  button_outlined_text_204_DM: xhoppersColorStyles.primary_main_DM,
  button_outlined_text_250_DM: xhoppersColorStyles.primary_main_dark_DM,
  button_outlined_text_255_DM: xhoppersColorStyles.primary_main_dark_DM,
  primary_button_hover_LM: xhoppersColorStyles.primary_main_dark_LM,
  primary_button_hover_DM: xhoppersColorStyles.primary_main_DM,
  button_send_hover_LM: xhoppersColorStyles.primary_main_light_LM,
  button_send_hover_DM: xhoppersColorStyles.primary_main_dark_DM,
  button_outlined_disabled_text_LM: xhoppersColorStyles.primary_disabled_text_LM,
  button_outlined_disabled_text_DM: 'rgba(81, 204, 93, 0.12)', //#51CC5D1F green204_12__DM
  message_outgoing_background_LM: xhoppersColorStyles.secondary_background_message_outgoing_LM,
  message_outgoing_background_DM: xhoppersColorStyles.primary_main_LM,
  message_outgoing_quote_background_LM: 'rgba(211, 237, 203, 1)', // #D3EDCB
  message_outgoing_quote_background_DM: 'rgba(82, 147, 85, 1)', // #529355
  message_incoming_background_LM: xhoppersColorStyles.secondary_background_message_incoming_LM,
  message_incoming_background_DM: xhoppersColorStyles.secondary_background_message_incoming_DM,
  message_incoming_quote_background_LM: 'rgba(233, 233, 233, 1)', // #E9E9E9
  message_incoming_quote_background_DM: 'rgba(36, 42, 46, 1)', // #242A2Eff
  message_text_LM: 'rgba(41, 44, 49, 1)', // #292C31ff black49__LM
  message_text_DM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  message_link_LM: xhoppersColorStyles.primary_main_dark_LM,
  message_link_DM: xhoppersColorStyles.primary_main_dark_DM,
  container_background_LM: xhoppersColorStyles.secondary_background_message_incoming_LM,
  container_background_DM: xhoppersColorStyles.secondary_background_message_incoming_DM,
  input_focused_border_LM: xhoppersColorStyles.primary_disabled_text_LM,
  input_focused_border_DM: xhoppersColorStyles.primary_disabled_text_DM,
  chip_background_LM: xhoppersColorStyles.primary_disabled_hover_LM,
  chip_background_DM: xhoppersColorStyles.primary_disabled_hover_LM,
  call_dialog_background_LM: xhoppersColorStyles.secondary_background_active_state_LM,
  call_dialog_background_DM: xhoppersColorStyles.secondary_background_active_state_DM,
  call_popup_item_background_DM: '#77CB881F', //#77CB881F
  call_history_media_button_background_DM: 'rgba(152, 165, 174, 0.12)',
  secondary_button_hover_LM: xhoppersColorStyles.secondary_hover_on_white_state_LM,
  secondary_button_hover_DM: xhoppersColorStyles.primary_disabled_DM,
  button_on_gray_active_LM: xhoppersColorStyles.secondary_hover_state_LM,
  button_on_gray_active_hover_LM: xhoppersColorStyles.secondary_hover_state_LM,
  button_on_gray_active_08_DM: xhoppersColorStyles.states_active_DM,
  button_on_gray_active_18_DM: xhoppersColorStyles.primary_disabled_DM,
  button_on_gray_hover_LM: xhoppersColorStyles.states_hover_LM,
  button_on_gray_hover_08_DM: xhoppersColorStyles.states_active_DM,
  button_on_gray_hover_18_DM: xhoppersColorStyles.states_active_DM,
  missed_unread_background_LM: xhoppersColorStyles.primary_disabled_hover_LM,
  missed_unread_background_DM: xhoppersColorStyles.primary_disabled_DM,
  missed_unread_mark_background_LM: xhoppersColorStyles.primary_main_LM,
  missed_unread_mark_background_DM: xhoppersColorStyles.primary_main_dark_DM,
  close_icon_default_LM: 'rgba(147, 147, 147, 1)', //#939393
  button_background_DM: xhoppersColorStyles.primary_main_DM,
  integration_button_background_DM: xhoppersColorStyles.states_active_LM,
  audio_attachment_LM: 'rgba(0, 0, 0, 0.08)', // #000000CC black00_08
  file_size_outgoing_LM: xhoppersColorStyles.primary_main_LM,
  file_size_outgoing_DM: 'rgba(255, 255, 255, 0.6)', // #FFFFFF99 white00_60__DM
  inbox_controls_button_hover_LM: 'rgba(235, 247, 231, 1)', // #EBF7E7
  inbox_controls_button_hover_DM: xhoppersColorStyles.primary_disabled_DM,
  info_frame_accordion_DM: 'transparent',
  info_frame_accordion_expanded_DM: 'rgba(185, 200, 193, 0.06)', // #B9C8C10F
  info_frame_accordion_hover_LM: xhoppersColorStyles.primary_disabled_LM,
  info_frame_accordion_hover_DM: xhoppersColorStyles.states_active_DM,
  members_add_button_hover_DM: xhoppersColorStyles.primary_main_light_DM,
  incoming_message_animation_LM: xhoppersColorStyles.primary_disabled_DM,
  incoming_message_animation_DM: xhoppersColorStyles.primary_main_light_DM,
  outgoing_message_animation_LM: 'rgba(36, 194, 60, 0.5)', // #24C23C80 green194_50__LM
  outgoing_message_animation_DM: xhoppersColorStyles.primary_main_light_LM,
  main_gradient_start_LM: xhoppersColorStyles.primary_main_light_LM,
  main_gradient_start_DM: 'rgba(63, 151, 67, 1)', // #3F9743ff
  main_gradient_finish_LM: xhoppersColorStyles.primary_main_LM,
  main_gradient_finish_DM: 'rgba(43, 89, 45, 1)', // #2B592D
  outgoing_reference_link_background_LM: xhoppersColorStyles.states_active_LM,
  transfer_button_LM: xhoppersColorStyles.primary_disabled_LM,
  transfer_button_DM: xhoppersColorStyles.primary_disabled_DM,
  disabled_transfer_button_text_DM: 'rgba(81, 204, 93, 0.12)', // #51CC5D1F green204_12__DM
  transfer_item_hover_DM: xhoppersColorStyles.states_active_DM,
  brand_LM: xhoppersColorStyles.primary_main_LM,
  brand_DM: xhoppersColorStyles.primary_main_dark_DM,
  feedback_button_hover_LM: xhoppersColorStyles.primary_disabled_LM,
  feedback_button_hover_DM: xhoppersColorStyles.secondary_background_active_state_DM,
  empty_results_rect_LM: xhoppersColorStyles.secondary_background_message_outgoing_LM,
  empty_results_rect_DM: 'rgba(81, 204, 93, 0.12)', // #51CC5D1f green204_12__DM
  checkbox_checked_LM: xhoppersColorStyles.primary_disabled_hover_LM,
  checkbox_checked_DM: xhoppersColorStyles.secondary_hover_state_DM,
  insights_playback_controls_background_LM: xhoppersColorStyles.secondary_background_message_incoming_LM,
  insights_playback_controls_background_DM: 'rgba(30, 37, 45, 0.8)', // #1E252Dcc black45_80__DM
  insights_playback_controls_button_active_LM: xhoppersColorStyles.secondary_hover_state_LM,
  insights_playback_controls_button_active_DM: xhoppersColorStyles.secondary_hover_state_DM,
  insights_playback_controls_button_hover_LM: xhoppersColorStyles.primary_disabled_LM,
  insights_playback_controls_button_hover_DM: xhoppersColorStyles.primary_disabled_DM,
  insights_playback_controls_border_LM: xhoppersColorStyles.primary_disabled_hover_LM,
  insights_playback_controls_border_DM: 'rgba(255, 255, 255, 0.12)', // #FFFFFF1F white00_12
  insights_playback_chunk: xhoppersColorStyles.primary_main_LM,
  insights_time_code_chunk_LM: xhoppersColorStyles.primary_disabled_text_LM,
  insights_time_code_chunk_DM: 'rgba(10, 49, 12, 1)', // #0A310C
  insights_slider_seek_hover_LM: 'rgba(109, 181, 112, 1)', // #6DB570,
  insights_slider_seek_hover_DM: 'rgba(27, 85, 29, 1)', // #1B551D,
  insights_comments_card_background_LM: xhoppersColorStyles.secondary_background_message_outgoing_LM,
  insights_comments_card_background_DM: xhoppersColorStyles.secondary_hover_state_DM,
  insights_comments_card_hex_background_LM: xhoppersColorStyles.primary_main_light_LM,
  insights_comments_card_hex_background_DM: xhoppersColorStyles.primary_main_LM,
  insights_comments_button_hover_background_DM: 'rgba(22, 200, 58, 0.12)', // #16C83A1F
  insights_translate_button_hover_DM: xhoppersColorStyles.states_hover_DM,
  insights_transcription_highlight_LM: xhoppersColorStyles.primary_disabled_hover_LM,
  insights_transcription_highlight_DM: xhoppersColorStyles.primary_disabled_DM,
  pinned_indicator_active_regular_LM: xhoppersColorStyles.primary_main_LM,
  pinned_indicator_active_regular_DM: xhoppersColorStyles.primary_main_dark_DM,
  pinned_indicator_active_dynamic_LM: xhoppersColorStyles.primary_main_LM,
  pinned_indicator_active_dynamic_DM: xhoppersColorStyles.primary_main_dark_DM,
  pinned_indicator_LM: 'rgba(85, 186, 55, 0.7)', // #55BA37B2
  pinned_indicator_DM: xhoppersColorStyles.primary_main_DM,
  pinned_indicator_corner_LM: xhoppersColorStyles.primary_disabled_hover_LM,
  pinned_indicator_corner_DM: 'rgba(58, 63, 60, 1)', //#3A3F3C
  primary_button_disabled_text_LM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  primary_button_disabled_text_DM: 'rgba(97, 108, 102, 1)', // ##616C66ff
  secondary_button_background__LM: 'rgba(152, 165, 174, 0.12)', // #98A5AE1f gray174_12
  info_frame_accordion_title_active_hover__LM: xhoppersColorStyles.secondary_background_message_outgoing_LM,
  info_frame_accordion_title_active_hover__DM: xhoppersColorStyles.states_active_DM,
  predefined_reply_button_hover_LM: xhoppersColorStyles.primary_disabled_LM,
  predefined_reply_button_hover_DM: xhoppersColorStyles.primary_disabled_hover_LM,

  integrations_icon_action_regular_LM: 'rgb(139,156,143)', // #8B8F9Cff
  integrations_icon_action_regular_DM: 'rgba(117, 118, 121, 1)', // #757679ff gray117__LM
  integrations_icon_action_field_LM: 'rgb(139,156,143)', // #8B8F9Cff
  integrations_icon_action_field_DM: 'rgba(157, 159, 162, 1)', // #9D9FA2ff gray162__LM
  integrations_icon_action_regular_hover_LM: xhoppersColorStyles.primary_main_LM,
  integrations_icon_action_regular_hover_DM: xhoppersColorStyles.primary_main_DM,
  integrations_icon_action_regular_hover_mui_DM: xhoppersColorStyles.primary_main_DM,
  integrations_icon_color_LM: xhoppersColorStyles.primary_main_LM,
  integrations_icon_color_DM: xhoppersColorStyles.primary_main_DM,
  integrations_action_background_LM: xhoppersColorStyles.primary_main_LM,
  integrations_action_background_DM: xhoppersColorStyles.primary_main_DM,
  integrations_alert_background_LM: xhoppersColorStyles.secondary_hover_state_LM,
  integrations_alert_background_DM: xhoppersColorStyles.primary_disabled_DM,
  integrations_button_disabled_background_LM: xhoppersColorStyles.primary_disabled_LM,
  integrations_button_disabled_background_DM: xhoppersColorStyles.primary_disabled_DM,
  integrations_accordion_badge_LM: xhoppersColorStyles.primary_disabled_DM,
  integrations_accordion_badge_DM: xhoppersColorStyles.states_active_DM,
  integrations_icon_next_step_LM: xhoppersColorStyles.primary_disabled_hover_LM,
  integrations_icon_next_step_DM: xhoppersColorStyles.primary_disabled_DM,
  integrations_sf_badge_LM: xhoppersColorStyles.secondary_background_active_state_LM,
  integrations_sf_badge_DM: xhoppersColorStyles.primary_disabled_DM,
  integrations_icon_text_editor_LM: xhoppersColorStyles.primary_main_dark_DM,
  integrations_icon_text_editor_DM: xhoppersColorStyles.secondary_hover_state_DM,
  integrations_tooltip_background_LM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  integrations_tooltip_background_DM: 'rgba(43, 51, 59, 1)', // #2B333Bff gray59__DM
  integrations_tooltip_text_LM: 'rgba(41, 44, 49, 1)', // #292C31ff black49__LM
  integrations_tooltip_text_DM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  integrations_steps_unfinished_background: 'rgba(156, 156, 156, 0.18)', // #9C9C9C2E
};

let themeColorsToUse;

switch (APP_BUILD_NAME) {
  case BuildAppNames.XBEES:
    themeColorsToUse = xbeesThemeColors;
    break;
  case BuildAppNames.XHOPPERS:
    themeColorsToUse = xhoppersThemeColors;
    break;
  default:
    themeColorsToUse = xhoppersThemeColors;
}

export const ThemeColors = {
  ...oldColors,
  ...themeColorsToUse,

  lightBlue237: 'rgba(199, 219, 237, 1)', // #C7DBEDff
  black71__DM: 'rgba(42, 56, 71, 1)', // #2A3847ff
  orange212: 'rgba(212, 93, 0, 1)', // #D45D00ff
  orange255: 'rgba(255, 181, 0, 1)', // #FFB500ff
  blueBlack41: 'rgba(19, 30, 41, 1)', // #131E29ff
  blue200__LM: 'rgba(22, 123, 200, 1)', // #167BC8ff
  blue204__DM: 'rgba(5, 118, 204, 1)', // #0576CCff
  blueAccent168__LM: 'rgba(19, 103, 168, 1)', // #1367A8ff
  blueAccent168_20__LM: 'rgba(19, 103, 168, 0.2)', // #1367A8
  blueAccent255__DM: 'rgba(23, 174, 255, 1)', // #17AEFFff
  blue200_50__LM: 'rgba(22, 123, 200, 0.5)', // #167BC880
  blue204_50__DM: 'rgba(5, 118, 204, 0.5)', // #0576CC80
  blue200_18__LM: 'rgba(22, 123, 200, 0.18)', // #167BC82e
  blue204_18__DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2e
  blue200_12__LM: 'rgba(22, 123, 200, 0.12)', // #167BC81E
  blue200_08__LM: 'rgba(22, 123, 200, 0.08)', // #167BC814
  blue204_08__DM: 'rgba(5, 118, 204, 0.08)', // #0576CC14
  blue204_14__DM: 'rgba(5, 118, 204, 0.14)',
  blue204_32__DM: 'rgba(5, 118, 204, 0.32)',
  blue250: 'rgba(53, 164, 250, 1)', // #35A4FAff
  white00: 'rgba(255, 255, 255, 1)', // #FFFFFFff
  white00__LM: 'rgba(255, 255, 255, 1)', // #FFFFFFff
  white00_80__LM: 'rgba(255, 255, 255, 0.8)', // #FFFFFFcc
  white00_70__LM: 'rgba(255, 255, 255, 0.7)', // #FFFFFFb3
  white00_60__LM: 'rgba(255, 255, 255, 0.6)', // #FFFFFF99
  white00_60__DM: 'rgba(255, 255, 255, 0.6)', // #FFFFFF99
  white00_50: 'rgba(255, 255, 255, 0.5)', // #FFFFFF80
  white00_40: 'rgba(255, 255, 255, 0.4)', // #FFFFFF66
  white00_16: 'rgba(255, 255, 255, 0.16)', // #FFFFFF29
  white00_12: 'rgba(255, 255, 255, 0.12)', // #FFFFFF1F
  white00_08: 'rgba(255, 255, 255, 0.08)', // #FFFFFFCC
  white00_04: 'rgba(255, 255, 255, 0.04)', // #FFFFFF0A
  white00_00: 'rgba(255, 255, 255, 0.0)', // #FFFFFF00
  black00: 'rgba(0, 0, 0, 1)', // #000000ff
  black00_80: 'rgba(0, 0, 0, 0.8)', // #000000cc
  black00_50: 'rgba(0, 0, 0, 0.5)', // #00000080
  black00_16: 'rgba(0, 0, 0, 0.16)', // #00000029
  black00_08: 'rgba(0, 0, 0, 0.08)', // #000000CC
  black00_04: 'rgba(0, 0, 0, 0.04)', // #0000000A
  black00_00: 'rgba(0, 0, 0, 0.0)', // #00000000
  black22_06: 'rgba(9, 15, 22, 0.06)', // #090F1606
  black22__DM: 'rgba(9, 15, 22, 1)', // #090F16ff
  black22_70__DM: 'rgba(9, 15, 22, 0.7)', // #090F16b3
  black22_60__DM: 'rgba(9, 15, 22, 0.6)', // #090F1699
  black30__DM: 'rgba(16, 22, 30, 1)', // #10161Eff
  black30_60__DM: 'rgba(16, 22, 30, 0.6)', // #10161E99
  black36__DM: 'rgba(22, 28, 36, 1)', // #161C24ff
  black45__DM: 'rgba(30, 37, 45, 1)', // #1E252Dff
  black45_94__DM: 'rgba(30, 37, 45, 0.94)', // #1E252Df0
  black45_80__DM: 'rgba(30, 37, 45, 0.8)', // #1E252Dcc
  black45_70__DM: 'rgba(30, 37, 45, 0.7)', // #1E252Db3
  black46__DM: 'rgba(32, 39, 46, 1)', // #20272Eff
  black49__LM: 'rgba(41, 44, 49, 1)', // #292C31ff
  black49_94__LM: 'rgba(41, 44, 49, 0.94)', // #292C31EF
  gray117__LM: 'rgba(117, 118, 121, 1)', // #757679ff
  gray131__LM: 'rgba(127, 128, 131, 1)', // #7F8083ff
  gray177__LM: 'rgba(174, 175, 177, 1)', // #AEAFB1ff
  gray225__DM: 'rgba(223, 224, 225, 1)', // #DFE0E1ff
  gray162__DM: 'rgba(157, 159, 162, 1)', // #9D9FA2ff
  gray111__DM: 'rgba(102, 106, 111, 1)', // #666A6Fff
  gray242__LM: 'rgba(242, 242, 242, 1)', // #F2F2F2ff
  gray91: 'rgba(91, 91, 91, 1)', //#5B5B5BFF
  black22_60__LM: 'rgba(9, 15, 22, 0.6)', // #090F1699
  grayBlue156__LM: 'rgba(139, 143, 156, 1)', // #8B8F9Cff
  gray234__LM: 'rgba(230, 232, 234, 1)', // #E6E8EAff
  gray47: 'rgba(60, 54, 47, 1)', // #3C362FFF
  gray70__DM: 'rgba(63, 66, 70, 1)', // #3F4246ff
  gray59__DM: 'rgba(43, 51, 59, 1)', // #2B333Bff
  gray46__DM: 'rgba(36, 42, 46, 1)', // #242A2Eff
  gray_156_18: 'rgba(156, 156, 156, 0.18)', // #9C9C9C2E
  gray174_12: 'rgba(152, 165, 174, 0.12)', // #98A5AE1f
  gray174_06: 'rgba(152, 165, 174, 0.06)', // #98A5AE0F
  gray174_04: 'rgba(152, 165, 174, 0.04)', // #98A5AE0A
  gray174_10: 'rgba(152, 165, 174, 0.1)', // #98A5AE1a
  green36_05_LM: 'rgba(36, 194, 60, 0.05)', // #24C23C0C
  green194__LM: 'rgba(36, 194, 60, 1)', // #24C23Cff
  green194_50__LM: 'rgba(36, 194, 60, 0.5)', // #24C23C80
  green194_12__LM: 'rgba(36, 194, 60, 0.12)', // #24C23C1f
  green204__DM: 'rgba(81, 204, 93, 1)', // #51CC5Dff
  green204_50__DM: 'rgba(81, 204, 93, 0.5)', // #51CC5D80
  green204_12__DM: 'rgba(81, 204, 93, 0.12)', // #51CC5D1f
  green204_05_DM: 'rgba(81, 204, 93, 0.05)', // #51CC5D0C
  green65_LM: 'rgba(48, 153, 65, 1)', // #309D41
  green85_LM: 'rgba(75, 181, 85, 1)', // #4BB555
  red218_16_DM: 'rgba(218, 44, 34, 0.16)', // #DA2C2228
  red238__LM: 'rgba(238, 65, 65, 1)', // #EE4141ff
  red238_24__LM: 'rgba(238, 65, 65, 0.24)', // #EE41413d
  red238_15__LM: 'rgba(238, 65, 65, 0.15)', // #EE414126
  red238_08__LM: 'rgba(238, 65, 65, 0.08)', // #EE414114
  red247__DM: 'rgba(247, 90, 91, 1)', // #F75A5Bff
  red247_24__DM: 'rgba(247, 90, 91, 0.24)', // #F75A5B3d
  red247_15__DM: 'rgba(247, 90, 91, 0.15)', // #F75A5B26
  red247_08__DM: 'rgba(247, 90, 91, 0.08)', // #F75A5B14
  deepBlue116: 'rgba(22, 76, 116, 1)', // #164C74ff
  deepBlue116_08: 'rgba(22, 76, 116, 0.08)', // #164C7414
  deepBlue116_04: 'rgba(22, 76, 116, 0.04)', // #164C74a
  yellow250__LM: 'rgba(250, 204, 42, 1)', // #FACC2Aff
  yellow252__DM: 'rgba(252, 215, 81, 1)', // #FCD751ff
  orange232__LM: 'rgba(232, 125, 7, 1)', // #E87D07FF
  orange232_10__LM: 'rgba(232, 125, 7, 0.1)', //#E87D0719
  orange239__LM: 'rgba(239, 108, 0, 1)', // #EF6C00ff
  orange251__DM: 'rgba(251, 160, 86, 1)', // #FBA056ff
  lightBlue186_04: 'rgba(127, 161, 186, 0.04)', // #7FA1BA0A
  lightBlue186_08: 'rgba(127, 161, 186, 0.08)', // #7FA1BA14
  lightBlue186_12: 'rgba(127, 161, 186, 0.12)', // #7FA1BA1F
  lightBlue186_14: 'rgba(127, 161, 186, 0.14)', // #7FA1BA23
  brown239__LM: 'rgba(187, 93, 7, 1)', // #BB5D07ff
  yellow250_15__LM: 'rgba(250, 204, 42, 0.15)', // #FACC2A26
  yellow252_15__DM: 'rgba(247, 206, 59, 0.15)', // #F7CE3B26
  orange239_15__LM: 'rgba(239, 108, 0, 0.24)', // #EF6C003d
  orange239_20__LM: 'rgba(239, 108, 0, 0.20)', // #EF6C0033
  orange251_24__DM: 'rgba(251, 160, 86, 0.24)', // #FBA0563d
  brown187_24__LM: 'rgba(187, 93, 7, 0.24)', // #BB5D073d
  brown187__LM: 'rgba(187, 93, 7, 1)', // #BB5D07ff
  lightBlue244__LM: 'rgba(236, 241, 244, 1)', // #ECF1F4ff
  lightBlue130_18__DM: 'rgba(130, 186, 229, 0.18)', //#82BAE52E
  black34__DM: 'rgba(20, 27, 34, 1)', // #141B22ff
  lightBlue238: 'rgba(210, 225, 238, 1)', // #D2E1EEff
  black22_06__LM: 'rgba(9, 15, 22, 0.06)', // #090F16f
  white00_12__DM: 'rgba(255, 255, 255, 0.12)', // #FFFFFF1f
  lmSize2px_18: 'rgba(22, 123, 200, 0.18)', // #167BC82e
  black37_18_DM: 'rgba(18, 28, 37, 1)', // #121C25
  blue232_LM: 'rgb(177, 207, 232)', // #B1CFE8
  blue_light_255_LM: 'rgb(244, 250, 255)', // #F4FAFF
  blue_light_50_DM: 'rgb(18, 36, 50)', // #122432
  gray249_LM: 'rgb(247, 249, 249)', // #F7F9F9
  gray243_LM: 'rgb(236,240,243)', // #ECF0F3
  green26_LM: 'rgb(1, 167, 26)', // #01A71A
  box_shadow__LM: 'rgba(7, 12, 16, 0.22)', // #070C1038
  secondaryHover__LM: 'rgba(236, 244, 251, 1)', // #ECF4FBFF
  primaryDisabled_LM: 'rgba(177, 207, 232, 1)', // #B1CFE8FF
  shadowFrame_12__LM: 'rgba(0, 0, 0, 0.12)', // #0000001E
  purple__LM: 'rgba(155, 64, 232, 1)', // #9B40E8FF
  purple__DM: 'rgba(189, 56, 252, 1)', // #BD38FCFF
  purple_160_08: 'rgba(139, 94, 160, 0.08)', // #8B5EA014
  hoverBg__DM: 'rgba(43, 52, 57, 1)', // #2B3439FF
  red205__LM: 'rgba(205, 74, 86, 1)', // #CD4A56FF
  lightblue_246__LM: 'rgba(210,230,246, 1)', // #D2E6F6
  blue209__DM: 'rgba(5,118,204, 0.5)', // #0576CC
  blueAccent255_30__DM: 'rgba(23, 174, 255, 0.3)', // #17AEFF4D
  white249__LM: 'rgba(246, 248, 249, 1)', // #F6F8F9
  blue222__LM: 'rgba(133, 183, 222, 1)', // #85B7DE
  blue_dark_92__DM: 'rgba(19, 60, 92, 1)', // #133C5C
  gray_245__DM: 'rgba(243, 244, 245, 1)', // #F3F4F5
  gray_40__DM: 'rgba(26, 33, 40, 1)', // #1A2128
  yellow_219__LM: 'rgba(255, 247, 219, 1)', // #FFF7DB
  yellow_13__LM: 'rgba(142, 98, 13, 1)', // #8E620D
  green_223__LM: 'rgba(226, 247, 223, 1)', // #E2F7DF
  green_57__LM: 'rgba(46, 123, 57, 1)', // #2E7B39
  brown_194__LM: 'rgba(249, 217, 194, 1)', // #F9D9C2
  brown_12__LM: 'rgba(150, 53, 12, 1)', // #96350C
  purple_248__LM: 'rgba(229, 205, 248, 1)', // #E5CDF8
  purple_146__LM: 'rgba(80, 14, 146, 1)', // #500E92
  yellow_42_DM: 'rgba(250, 204, 42, 1)', // #FACC2A
  yellow_42_02_DM: 'rgba(250,204,42, 0.2)', // #FACC2A33
  green_73_DM: 'rgba(54, 179, 73, 1)', // #36B349
  green_60_02_DM: 'rgba(36,194,60, 0.2)', // #24C23C33
  orange_0_DM: 'rgba(239, 108, 0, 1)', // #EF6C00
  orange_0_02_DM: 'rgba(239,108,0, 0.2)', // #EF6C00
  purple_255_DM: 'rgba(187, 119, 255, 1)', // #BB77FF
  purple_228_02_DM: 'rgba(160,75,228, 0.2)', // #A04BE433
  white_249_LM: 'rgba(249, 249, 249, 1)', // #F9F9F9
  white_251_LM: 'rgb(251, 251, 251)', // #FBFBFB
  yellow_49__LM: 'rgb(251, 197, 49)', // #FBC531
  yellow_81__DM: 'rgb(252, 215, 81)', // #FCD751
  gray_228__LM: 'rgb(228, 228, 228)', // #E4E4E4
};
